.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.loading {
    border-width: 0px !important;
}

.lds-dual-ring {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:before{
    position: absolute;
    background-image: url(https://s3.amazonaws.com/akcelita-artsense/images/logo-damefeedback.png);
    background-position: center center;
    background-size: 56px 46px;
    left:-10px;
    content: ' ';
    display: block;
    width: 56px;
    height: 46px;
    margin: 1px;
    animation: lds-dual-ring-logo 1.2s ease-out alternate infinite;
}

@keyframes lds-dual-ring-logo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
